import { Currency } from "@/autoGeneratedGlobalTypes";

import { MARKETING_YANDEX_ID } from "./constants";
import { GtmDataLayerObject } from "./types";

const reachYandexMetricsGoal = (goalId: string) => {
  console.log("Goal reached ", goalId);
  // @ts-ignore
  if (typeof ym !== "undefined") {
    ym(MARKETING_YANDEX_ID, "reachGoal", goalId);
  }
};

const pushToDataLayer = (objectToPush: GtmDataLayerObject) => {
  console.log("GTM tag call", objectToPush);

  // Triggering GTM tag
  dataLayer.push(objectToPush);
};

// todo: Simplify marketing hooks.
//  We should set marketing hook as parameter, using enum, like this:
//  marketingHook(USER_REGISTRATION);
//  marketingHook = (hookName: marketingHooksEnum) => {
//    if (typeof ym !== "undefined") {
//      ym(MARKETING_YANDEX_ID, "reachGoal", hookName);
//    }
//    return true;
//  }

// Past this parameter into the URL "?_ym_debug=1" to test the reachGoal hook

/**
 * Runs Yandex metric goal and GTM triger for login of a new user
 * @returns
 */
export const marketingUserFirstLogin = ({ userId = "0" }: { userId: string }) => {
  reachYandexMetricsGoal("User-First-Login");

  // Triggering GTM tag
  dataLayer.push({
    event: "user-first-login",
    user_id: userId,
  });

  return true;
};

/**
 * Yandex metric goal for user filling email for the first time
 * Business considers filling out a questionnaire as registration now
 * @returns
 */
export const marketingUserFilledEmailForTheFirstTimeHandler = () => {
  reachYandexMetricsGoal("User-Registered");
  return true;
};

/**
 * Yandex metric goal for user authorization via Google
 * @returns
 */
export const marketingUserGoogleAuthorizationHandler = () => {
  reachYandexMetricsGoal("Google-Authorization");
  return true;
};

/**
 * Yandex metric goal for user authorization via VK
 * @returns
 */
export const marketingUserVkAuthorizationHandler = () => {
  reachYandexMetricsGoal("Vk-Authorization");
  return true;
};

/**
 * Yandex metric goal for button click (no matter if the user is authorized)
 * @returns
 */
export const marketingCallButtonClickHandler = () => {
  reachYandexMetricsGoal("Call-Button");
  return true;
};

/**
 * Yandex and Google metrics e-commerce code for expert card click
 * @returns
 */
export const marketingExpertCardClickHandler = (
  id: number,
  name: string,
  callPrice?: number,
  skills?: string[],
  expertCardPosition?: number,
  sourcePath: string = "",
) => {
  // @ts-ignore
  if (typeof dataLayer !== "undefined") {
    // eslint-disable-next-line no-undef
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    const sourceName = sourcePath ? sourcePath.replace(/\/$|\/\?.*$|\?.*$/, "") : "Другой источник";
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: sourceName }, // Optional list property.
          // todo: add audio call and chat products when ready
          products: skills?.map((skill) =>
            ({
              id,
              name,
              price: callPrice,
              category: skill,
              position: expertCardPosition,
            })),
        },
      },
    });
  }
  return true;
};

/**
 * Yandex and Google metrics e-commerce code for landing on expert page
 * @returns
 */
export const marketingOpenExpertPageHandler = (
  id: number,
  name: string,
  callPrice: number,
  skills: string[],
  sourcePath: string = "",
) => {
  if (typeof dataLayer !== "undefined") {
    // eslint-disable-next-line no-undef
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    const sourceName = sourcePath ? sourcePath.replace(/\/$|\/\?.*$|\?.*$/, "") : "Другой источник";
    // eslint-disable-next-line no-undef
    dataLayer.push({
      ecommerce: {
        detail: {
          actionField: { list: sourceName },
          // todo: add audio call and chat products when ready
          products: skills.map((skill) =>
            ({
              id,
              name,
              price: callPrice,
              category: skill,
            })),
        },
      },
    });
  }
  return true;
};

/**
 * Runs Yandex and Google metrics calls for successful payment
 * @returns
 */
export const marketingSuccessPaymentHandler = ({
  paymentId,
  amount,
  currency,
  name = "Пополнение баланса",
  userId = "0",
  userEmail,
}: {
  paymentId: number | undefined;
  amount: number;
  currency: Currency;
  name?: string;
  userId?: string;
  userEmail?: string;
}) => {
  // Yandex Metrics goal
  reachYandexMetricsGoal("Payment-Success");
  reachYandexMetricsGoal("Flocktory_successful_payment");

  // Yandex and Google metrics e-commerce code for successful payment
  if (typeof dataLayer !== "undefined") {
    // eslint-disable-next-line no-undef
    dataLayer.push({ ecommerce: null }); // Clearong the previous ecommerce object.

    // eslint-disable-next-line no-undef
    dataLayer.push({
      ecommerce: {
        currencyCode: currency,
        purchase: {
          actionField: {
            id: paymentId || "",
          },
          products: [
            {
              name,
              price: amount,
            },
          ],
        },
      },
    });

    // Triggering GTM tag (for CityAds and flocktory_successful_payment maybe other agencies)
    dataLayer.push({
      event: "successful-payment",
      user_id: userId,
      amount,
      user_email: userEmail,
      payment_id: paymentId,
    });
  }
  return true;
};

/**
 * Runs Yandex metrics goal and GTM trigger for first successful payment
 * @returns
 */
export const marketingFirstSuccessPaymentHandler = ({
  amount,
  userId = "0",
  userEmail,
  paymentId,
}: {
  amount: number;
  userId?: string;
  userEmail?: string;
  paymentId: number | undefined;
}) => {
  // Yandex Metrics goal
  reachYandexMetricsGoal("First-Payment");
  reachYandexMetricsGoal("Flocktory_first-Payment");

  // Triggering GTM tag
  dataLayer.push({
    event: "first-successful-payment",
    user_id: userId,
    amount,
    user_email: userEmail,
    payment_id: paymentId,
  });

  return true;
};

/**
 * Yandex metric goal for "read more" button click on main page
 * @returns
 */
export const marketingMainPageReadMoreClickedHandler = () => {
  reachYandexMetricsGoal("Main-Page-Read-More-Clicked");
  return true;
};

/**
 * Yandex metric goal for starting a video call
 * @returns
 */
export const marketingCallStartedHandler = () => {
  reachYandexMetricsGoal("Call-Started");
  return true;
};

/**
 * Yandex metric goal for starting a phone call
 * @returns
 */
export const marketingPhoneCallStartedHandler = () => {
  reachYandexMetricsGoal("phone_call");
  return true;
};

/**
 * Yandex metric goal for connecting both expert and client to call
 * @returns
 */
export const marketingSessionStartedHandler = () => {
  reachYandexMetricsGoal("Session-Started");
  return true;
};

/**
 * Yandex metric goal for playing audio greeting
 * @returns
 */
export const marketingAudioGreetingPlayHandler = () => {
  reachYandexMetricsGoal("Audio-Greeting-Play");
  return true;
};

/**
 * Runs Yandex metric goal and GTM triger for free consultation
 * @returns
 */
export const marketingFreeConsultationHandler = () => {
  reachYandexMetricsGoal("Free_consultation");

  // Triggering GTM tag
  dataLayer.push({
    event: "free-consultation",
  });

  return true;
};

/**
 * YM Main page goal
 */
export const marketingMainPage1 = () => {
  reachYandexMetricsGoal("see_experts1");
  return true;
};

export const marketingMainPage2 = () => {
  reachYandexMetricsGoal("see_experts2");
  return true;
};

export const marketingMainPage3 = () => {
  reachYandexMetricsGoal("see_experts3");
  return true;
};

export const marketingMainPage4 = () => {
  reachYandexMetricsGoal("see_experts4");
  return true;
};

export const marketingMainPage5 = () => {
  reachYandexMetricsGoal("Ask_Question");
  return true;
};

export const marketingMainPage6 = () => {
  reachYandexMetricsGoal("get_free");
  return true;
};

/**
 * Yandex metric goal for continuing consultation
 * @returns
 */
export const marketingContinueConsultation = () => {
  reachYandexMetricsGoal("continue_consultation");
  return true;
};

/**
 * Yandex metric goal for viewing catalog
 * @returns
 */
export const marketingViewCatalog = () => {
  reachYandexMetricsGoal("view_catalog");
  return true;
};

/**
 * Runs Yandex metric goal and GTM triger for buttons
 * @returns
 */
export const customMarketingHandler = (metricsId: string) => {
  if (!metricsId) {
    return false;
  }

  reachYandexMetricsGoal(metricsId);

  // Triggering GTM tag
  dataLayer.push({
    event: metricsId,
  });

  return true;
};

/**
 * Runs Yandex metric goal and GTM triger for gift box button click
 * @returns
 */
export const marketingGiftBoxClickHandler = () => {
  reachYandexMetricsGoal("gift_box_click");

  // Triggering GTM tag
  dataLayer.push({
    event: "gift_box_click",
  });

  return true;
};

/**
 * Runs Yandex metric goal and GTM triger when gift is sent
 * @returns
 */
export const marketingGiftSentHandler = (giftId?: number) => {
  if (!giftId) {
    return false;
  }

  reachYandexMetricsGoal(`gift_sent_${giftId}`);

  // Triggering GTM tag
  dataLayer.push({
    event: "gift_sent",
    giftId,
  });

  return true;
};

export const marketingTrackForVideoCallHandler = () => {
  reachYandexMetricsGoal(`video_call_button_click`);

  // Triggering GTM tag
  dataLayer.push({
    event: "video_call_button_click",
  });

  return true;
};

export const marketingTrackForPhoneCallHandler = () => {
  reachYandexMetricsGoal(`phone_call_button_click`);

  // Triggering GTM tag
  dataLayer.push({
    event: "phone_call_button_click",
  });

  return true;
};

export const marketingTrackFlocktoryExchange = (clientEmail: string) => {
  reachYandexMetricsGoal(`flocktory_exchange_gift`);

  // Triggering GTM tag
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({
      event: "flocktory_exchange_gift",
      user_email: clientEmail,
    });
  }
  return true;
};

export const marketingTrackGet4clickExchange = (clientEmail: string) => {
  reachYandexMetricsGoal(`get4click_exchange_banner`);

  // Triggering GTM tag
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({
      event: "get_banner_4Click",
      user_email: clientEmail,
    });
  }
  return true;
};

/**
 * Runs Yandex metric goal and GTM triger when promocode is applied
 * @returns
 */
export const marketingPromocodeAppliedHandler = (promocode?: string) => {
  if (!promocode) {
    return false;
  }

  reachYandexMetricsGoal("promocode");

  pushToDataLayer({
    event: "promocode",
    promocode,
  });

  return true;
};
